// import { FaTwitter, FaDiscord, FaEnvelope } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";

function App() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="pt-12">
          <div className="prose font-serif text-2xl leading-relaxed">
            <p>👋</p>
            <p>
              We’re a rag-tag band of creatives, misfits, technologists{" "}
              <span className="font-amp italic">&amp;</span> dreamers. Together
              we make up Numb Co.
            </p>
            <p>
              <b className="text-3xl">
                We are the product, strategy, and technology partner for your
                next NFT project.
              </b>
            </p>
            <p>
              We partner with people like you who know the power of community.
            </p>
            <p>
              Community that places ownership into the hands of creators.
              Community that forges authentic connections between fans and the
              creators, causes, and ideas that they love.
            </p>
            <p>
              We both know know that community is the beginning, middle, and end
              and we’ve got your back to support you through your next NFT
              project and bring your vision to life.
            </p>
          </div>
          <div className="m-12 text-center">
            <span className="text-2xl">&#9778;</span>
          </div>
          <div className="prose font-serif text-2xl leading-relaxed">
            <p className="italic">How we collaborate with you</p>
            <div className="mt-10">
              <p>
                As your technical partners we support you with as much or as
                little as you need.
              </p>
              <p>
                This most often includes (but is not limited to) the following:
                <ul>
                  <li>
                    Creating and deploying a marketing{" "}
                    <span class="font-amp">&amp;</span> minting website.
                  </li>
                  <li>
                    Discussing and exploring the possibilities, limits, and
                    constraints of smart contracts.
                  </li>
                  <li>
                    Development, testing, and deployment of Ethereum compatible
                    smart contracts. To date we have built projects for both
                    Ethreum mainnet and the Polygon blockchain. We&rsquo;re
                    excited to explore other Ethereum compatible L2 chains and
                    side-chains.
                  </li>
                  <li>
                    Discussing, planning, and sharing security best practices.
                    From the use of hardware wallets, to understanding the
                    basics of personal{" "}
                    <abbr title="Operational security">OPSEC</abbr>, to
                    exploring healthy security hygiene in your Discord, we
                    ensure our partners are practicing safe-crypto.
                  </li>
                  <li>
                    Being on-hand throughout the lifecycle of your project. From
                    planning, throughout your launch, to final hand-off.
                    We&rsquo;re with you when you need us.{" "}
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="m-12 text-center">
            <span className="text-2xl">&#9783;</span>
          </div>
          <div className="prose font-serif text-2xl leading-relaxed">
            <p className="italic">Selected work</p>
            <div className="mt-10">
              <p>
                <a
                  href="https://www.cryptopackagedgoods.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Crypto Packaged Goods &ndash; Genesis &ndash;{" "}
                  <i>Technology Partner</i>
                </a>
              </p>
              <p>
                The premier community <q>Where crypto meets consumer.</q>{" "}
                Founded by{" "}
                <a
                  href="https://twitter.com/jaimeschmidt"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Jaime Schmidt
                </a>{" "}
                <span class="font-amp">&amp;</span>{" "}
                <a
                  href="https://twitter.com/chriscantino"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Chris Cantino
                </a>{" "}
                this project has reached a floor price high of 40 ETH.
                Attracting leaders, builders, creatives, and instigators from
                both web2 and web3,{" "}
                <a
                  href="https://twitter.com/CPGCLUB"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Club CPG
                </a>{" "}
                is a top-tier web3 community.
              </p>
            </div>
            <div className="mt-10">
              <p>
                <a
                  href="https://opensea.io/collection/minting-miracles-official"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Minting Miracles &ndash; <i>Technology Partner</i>
                </a>
              </p>
              <p>
                <q>
                  Minting Miracles are 3,000 superheroes living on the Ethereum
                  Blockchain.
                </q>{" "}
                The first project by NFT wunderkind{" "}
                <a
                  href="https://twitter.com/zachdoteth"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Zachary Cox
                </a>
                . Own a piece of NFT history by{" "}
                <a
                  href="https://etherscan.io/address/0x03392d7814aeacbdb55482ec54db618d38b7ef34#writeContract"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  minting your own Miracle
                </a>{" "}
                today. Mint price 0.06 ETH.
              </p>
            </div>
            {/* <div className="mt-10">
              <p>
                <a
                  href="https://closertonicole.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Closer To Nicole &ndash; <i>Technology Partner</i>
                </a>{" "}
              </p>
              <p>
                <q>Get closer than ever to Nicole Aniston.</q> Empowering{" "}
                <a
                  href="https://www.instagram.com/realnicoleaniston/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Nicole Aniston
                </a>
                , a woman at the top of a too often marginalized profession to
                create and own the entire economic relationship with her fans.
              </p>
            </div> */}
          </div>
          <div className="m-12 text-center">
            <span className="text-2xl">&#9780;</span>
          </div>
          {/* <div>
            <p className="italic">Team</p>
            <div className="mt-10">
              <p>
                Shawn &ndash; web3 degen
                <br />
                <a
                  href="https://twitter.com/sprice"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FaTwitter />
                </a>
              </p>
            </div>
            <div className="mt-10">
              <p>
                Kane &ndash; web3 degen
                <br />
                <a
                  href="https://twitter.com/TheCryptoKane"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FaTwitter />
                </a>
              </p>
            </div>
          </div> */}
          {/* <div className="m-12 text-center">
            <span className="text-2xl">&#9778;</span>
          </div> */}
          <div>
            <p className="prose font-serif text-2xl leading-relaxed">
              Connect with us today and let’s talk about your next NFT project.
            </p>
            <a
              href="mailto:hi@numbco.com?subject=Inquiry"
              rel="noreferrer noopener"
              className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-sans rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              {/* <button
                type="button"
                className="no-underline inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-sans rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              > */}
              <FaEnvelope className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              <span className="no-underline">Contact us today</span>
              {/* </button> */}
            </a>
            {/* <button
              type="button"
              className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-sans rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              <FaDiscord className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Join Our Discord
            </button> */}
          </div>
          <div className="m-12 text-center">
            <span className="text-2xl">&#9781;</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
